body {
  margin: 0;
}

.layout {
  .header {
    display: flex;
    align-items: center;

    .header-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .menu {
    height: 100%;
    border-right: 0;
  }

  .layout-content {
    padding: 0 50px;
  }

  .breadcrumb {
    margin: 16px 0;
  }
}

.auth {
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .input {
    margin-bottom: 10px;
  }
}

.job-details {
  .space {
    width: 100%;
  }

  .text {
    font-size: 16px;
  }
}

.job-card {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .buttons {
    display: flex;
    justify-content: flex-end;
  }

  .button-primary {
    margin-right: 10px;
  }
}

.jobs {
  .spin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .tabs {
    min-height: 100vh;

    .jobs-list {
      .download {
        margin-bottom: 10px;
      }
    }
  }
}

.user {
  height: 100vh;
  .card {
    margin-top: 16px;
    .btn-container {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      .btn {
        background-color: #f5222d;
        color: #fff;
      }
    }
  }
}

.users-list {
  height: 100%;
  min-height: 100vh;
  padding-bottom: 30px;

  .card-container {
    margin: 10px;

    .card {
      padding: 10px;
      border-radius: 5px;

      .card-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border-radius: 5px;
      }

      .text {
        padding: 5px;
        color: #fff;
        align-self: start;
        border-radius: 5px;
      }
    }
  }
}

.reviews {
  height: 100vh;
  padding: 20px;

  .card {
    margin-bottom: 20px;
  }
}
